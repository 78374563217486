import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useBreakpoints } from "@context/breakpoints";
import useOnScreen from "@helpers/useOnScreen";
import Fade from "@primitives/fade";
import { Button } from "@primitives/buttons";
import SplitText from "@utils/SplitText";
import { Stack } from "@washingtonpost/site-components";
import { theme } from "@src/theme";

const SectionHeadingWrapper = styled.div`
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;

  @media ${theme.breakpoints.large} {
    margin-bottom: 6rem;
  }
`;

const SectionHeading = ({ spacing, eyebrow, title, text, textSize, cta }) => {
  let textClasses = null;
  const breakpoints = useBreakpoints();
  const ref = useRef(null);
  const onScreen = useOnScreen(ref, 0.5);
  const [reveal, setReveal] = useState(false);

  const titleClass = breakpoints.large
    ? "font--heading-xl"
    : "font--heading-lg";

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);

  useEffect(() => {
    gsap.set(ref.current, {
      opacity: 0
    });

    if (reveal) {
      gsap.set(ref.current, {
        opacity: 1
      });

      const split = new SplitText(ref.current, {
        type: "lines",
        linesClass: "lineChildren"
      });

      if (breakpoints.large) {
        new SplitText(ref.current, {
          type: "lines",
          linesClass: "overflow-hidden"
        });
      }

      gsap.fromTo(
        split.lines,
        { y: 300, opacity: 0 },
        {
          duration: 1.5,
          y: 0,
          opacity: 1,
          stagger: 0.1,
          ease: "power2"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reveal]);

  if (typeof spacing === "undefined" && !spacing) {
    spacing = "xs";
  }

  if (typeof textSize !== "undefined" && textSize) {
    textClasses = `font--body-${textSize}`;
  } else {
    textClasses = "font--body-lg";
  }

  return (
    <SectionHeadingWrapper className="overflow-hidden" ref={ref}>
      <Fade>
        {eyebrow && <p className="font--eyebrow mb-sm">{eyebrow}</p>}
        <Stack space={spacing}>
          {title && <h3 className={titleClass}>{title}</h3>}
          {text && <p className={textClasses}>{text}</p>}
          {cta && (
            <Button as="a" href={cta?.url} target={cta?.target}>
              {cta?.title}
            </Button>
          )}
        </Stack>
      </Fade>
    </SectionHeadingWrapper>
  );
};

SectionHeading.propTypes = {
  size: PropTypes.string,
  spacing: PropTypes.string,
  eyebrow: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  textSize: PropTypes.oneOf(["sm", "lg", "tiny"]),
  cta: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string
  })
};

export default SectionHeading;
