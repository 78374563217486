import styled from "styled-components";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import { theme } from "@src/theme";

export const ContentSplitSectionHeadingWrapper = styled.div`
  @media ${theme.breakpoints.large} {
    padding-right: ${theme.spacing.md};
  }

  @media ${theme.breakpoints.max} {
    padding-right: ${theme.spacing.xl};
  }

  > * {
    max-width: 30em;
  }
`;

const Wrapper = styled.div.attrs(
  ({ bottom, centered, reversed, twoThirds, gap }) => ({
    className: classnames({
      bottom,
      centered,
      reversed,
      twoThirds,
      gap
    })
  })
)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media ${theme.breakpoints.large} {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  > * {
    width: 100%;

    @media ${theme.breakpoints.large} {
      flex-basis: 50%;
    }
  }

  &.reversed {
    @media ${theme.breakpoints.large} {
      flex-direction: row-reverse;
    }
  }

  &.bottom {
    @media ${theme.breakpoints.large} {
      align-items: flex-end;
    }
  }

  &.centered {
    @media ${theme.breakpoints.large} {
      align-items: center;
    }
  }

  &.twoThirds {
    @media ${theme.breakpoints.large} {
      > * {
        &:nth-child(even) {
          flex-basis: 33.33333%;
        }
        &:nth-child(odd) {
          flex-basis: 66.66666%;
        }
      }
    }
  }

  &.gap {
    gap: 2rem;
  }
`;

const ContentSplit = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

ContentSplit.propTypes = {
  children: PropTypes.node
};

export default ContentSplit;
