import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Cover from "@components/cover";
import Container from "@primitives/container";
import { CarouselContent } from "./carouselContent";
import { CarouselHeading } from "@primitives/carousel";
import { CircledArrowButton } from "@primitives/buttons/CircledArrowButton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

const Wrapper = styled.div`
  width: 100%;
`;

const CarouselTopContainer = styled(Container)``;

const CarouselMainContainer = styled.div``;

const ButtonContainer = styled(Container)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  marginBottom: 24,
  paddingLeft: theme.spacing.sm,
  paddingRight: theme.spacing.sm,

  [`@media ${theme.breakpoints.notSmall}`]: {
    justifyContent: "end",
    marginBottom: 36,
    marginTop: "-5rem"
  },

  [`@media ${theme.breakpoints.large}`]: {
    paddingLeft: theme.spacing.lg,
    paddingRight: theme.spacing.lg
  }
}));

const ButtonContainerInner = styled.div(({ theme }) => ({
  [`@media ${theme.breakpoints.notSmall}`]: {
    marginLeft: "auto"
  }
}));

const CarouselWrapper = styled.div`
  align-items: center;

  .swiper-slide {
    height: auto;
    width: 100%;

    > div {
      transform: scale(0.8);
      transition: transform 500ms ease-in-out;
    }

    &.swiper-slide-prev {
      transform-origin: 0% 50%;
    }

    &.swiper-slide-next {
      transform-origin: 100% 50%;
    }

    &.swiper-slide-active {
      > div {
        transform: scale(1);
      }
    }
  }
`;

const SuccessStories = ({ items, heading, eyebrow, className }) => {
  const loop = true;
  const carouselRef = useRef(null);

  const onNext = () => swiper.slideNext();
  const onPrevious = () => swiper.slidePrev();

  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);

  const onItemChange = ({ atStart, atEnd }) => {
    setAtStart(atStart);
    setAtEnd(atEnd);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const onTransition = (instance) => {
    setActiveIndex(instance.realIndex);

    if (onItemChange) {
      onItemChange({
        atStart: instance.isBeginning,
        atEnd: instance.isEnd,
        currentIndex: instance.realIndex
      });
    }
  };

  const [swiper, setSwiper] = useState(null);

  const onSwiper = (instance) => {
    setSwiper(instance);
  };

  return (
    <Wrapper>
      <CarouselTopContainer inner>
        <CarouselHeading>
          {eyebrow && <p className="font--eyebrow">{eyebrow}</p>}
          {heading && <h4 className="font--heading-sm-alt mb-md">{heading}</h4>}
        </CarouselHeading>
      </CarouselTopContainer>

      <CarouselMainContainer>
        <ButtonContainer inner>
          <ButtonContainerInner>
            <CircledArrowButton
              className="mr-xs"
              direction="left"
              disabled={!loop & atStart}
              onClick={onPrevious}
            />
            <CircledArrowButton
              className="mr-xs"
              direction="right"
              disabled={!loop & atEnd}
              onClick={onNext}
            />
          </ButtonContainerInner>
        </ButtonContainer>

        <CarouselWrapper className={className} itemWidth={`100%`}>
          <CarouselContent items={items} activeIndex={activeIndex} />
          <Swiper
            ref={carouselRef}
            onSwiper={onSwiper}
            onTransitionEnd={onTransition}
            slidesPerView={1}
            spaceBetween={0}
            loop={loop}
          >
            {items?.map((item, index) => (
              <SwiperSlide key={index}>
                <Cover key={index} number={`${index + 1}`} image={item.image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselWrapper>
      </CarouselMainContainer>
    </Wrapper>
  );
};

SuccessStories.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      ctaText: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  eyebrow: PropTypes.string,
  heading: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default SuccessStories;
