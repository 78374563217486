import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "@src/theme";
import { basePath } from "../../lib/basePath";

const Wrapper = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 600px;
  position: relative;
  width: 100%;

  @supports (padding-top: calc(var(--height) / var(--width) * 100%)) {
    position: relative;
    background-size: cover;
    --width: 350;
    --height: 650;

    @media ${theme.breakpoints.notSmall} {
      --width: 1040;
      --height: 750;
    }

    @media ${theme.breakpoints.large} {
      --width: 1440;
      --height: 750;
    }

    &::after {
      content: "";
      display: block;
      padding-top: calc(var(--height) / var(--width) * 100%);
    }

    img {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

const Cover = ({ image }) => {
  return (
    <Wrapper
      style={{ backgroundImage: `url("${basePath}${image}")` }}
    ></Wrapper>
  );
};

Cover.propTypes = {
  image: PropTypes.string.isRequired
};

export default Cover;
