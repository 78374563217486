import PropTypes from "prop-types";
import styled from "styled-components";
import classnames from "classnames";
import { ArrowLink } from "@primitives/arrowLink";
import { theme } from "@src/theme";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.75);
  background-blend-mode: multiply;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  max-height: 500px;
  max-width: 100%;
  padding: ${theme.spacing.xl} ${theme.spacing.sm};
  position: absolute;
  width: 100%;
  z-index: 2;

  @media ${theme.breakpoints.notSmall} {
    max-width: 720px;
    padding: ${theme.spacing.xl};
  }

  @media ${theme.breakpoints.large} {
    padding: ${theme.spacing.xxxl};
  }
`;

const Inner = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const Item = styled.div.attrs(({ active }) => ({
  className: classnames({
    active
  })
}))`
  position: absolute;
  height: 0;
  opacity: 0;
  top: 0;
  transition: all 300ms ease-in-out;
  visibility: hidden;

  &.active {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
`;

const Heading = styled.h3`
  max-width: 16em;
`;

export const CarouselContent = ({ items, activeIndex }) => {
  // eslint-disable-next-line react/prop-types
  const content = items?.map((item, index) => {
    const { title, ctaText, url } = item;
    return (
      <Item key={index} active={index === activeIndex}>
        {title && (
          <Heading className="font--heading-lg mb-xl white">{title}</Heading>
        )}
        {ctaText && url && (
          <ArrowLink href={url} mode="white">
            {ctaText}
          </ArrowLink>
        )}
      </Item>
    );
  });

  return (
    <Wrapper>
      <Inner>{content}</Inner>
    </Wrapper>
  );
};

CarouselContent.propTypes = {
  items: PropTypes.array,
  activeIndex: PropTypes.number
};
