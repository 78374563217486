import Link from "next/link";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "@src/theme";

const ArrowBase = (props) => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3536 4.35356C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464468C8.97631 0.269205 8.65973 0.269205 8.46447 0.464468C8.2692 0.65973 8.2692 0.976312 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53554C8.65973 7.7308 8.97631 7.7308 9.17157 7.53554L12.3536 4.35356ZM-8.74228e-08 4.5L12 4.5L12 3.5L8.74228e-08 3.5L-8.74228e-08 4.5Z"
      fill="currentColor"
    />
  </svg>
);

const Anchor = styled.a`
  color: ${({ mode, theme }) =>
    mode === "white" ? theme.colors.white : theme.colors.black};
  text-decoration: underline;

  &:hover,
  &:focus {
    svg {
      transform: translateX(8px);
    }
  }

  &:hover {
    color: ${({ mode, theme }) =>
      mode === "white" ? theme.colors.white : theme.colors.red};
  }

  &:focus {
    outline: 1px solid ${theme.colors.blue};
  }

  svg {
    transition: transform 200ms ease-in-out;
    transform: translate(0);
  }
`;

const Arrow = styled(ArrowBase)({
  marginLeft: 8
});

export const ArrowLink = ({ href, children, mode = "default", ...props }) => {
  return (
    <Link href={href} passHref>
      <Anchor className="font--caption" mode={mode} {...props}>
        {children}
        <Arrow />
      </Anchor>
    </Link>
  );
};

ArrowLink.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["default", "white"])
};
